import checkZipcodeError, { getDeliveryFees as getDeliveryFeesCore } from 'tilli-core/services/zipcode';

const ParisZipcodePrefix = '75';
const otherZipcodes = {
  92000: 'Nanterre',
  92100: 'Boulogne-Billancourt',
  92110: 'Clichy',
  92120: 'Montrouge',
  92130: 'Issy-Les-Moulineaux',
  92140: 'Clamart',
  92150: 'Suresnes',
  92170: 'Vanves',
  92190: 'Meudon',
  92200: 'Neuilly',
  92210: 'Saint Cloud',
  92300: 'Levallois-Perret',
  92320: 'Châtillon',
  92360: 'Meudon',
  92380: 'Garches',
  92410: 'Ville d‘Avray',
  92420: 'Vaucresson',
  92430: 'Marnes la Coquette',
  92500: 'Rueil-malmaison',
  92600: 'Asnières',
  92800: 'Puteaux',
  93100: 'Montreuil',
  93260: 'Les lilas',
  93310: 'Le Pré-Saint-Gervais',
  94160: 'Saint Mandé',
  94200: 'Ivry-sur-Seine',
  94205: 'Ivry-sur-Seine',
  94220: 'Charenton le pont',
  94300: 'Vincennes',
  13001: 'Marseille',
  13002: 'Marseille',
  13004: 'Marseille',
  13005: 'Marseille',
  13006: 'Marseille',
  13007: 'Marseille',
  13008: 'Marseille',
  13009: 'Marseille',
  13010: 'Marseille',
  13011: 'Marseille',
  13012: 'Marseille',
  13013: 'Marseille',
  69001: 'Lyon',
  69002: 'Lyon',
  69003: 'Lyon',
  69004: 'Lyon',
  69005: 'Lyon',
  69006: 'Lyon',
  69007: 'Lyon',
  69008: 'Lyon',
  69009: 'Lyon',
  69100: 'Villeurbanne',
  69110: 'St-Foy-lès-Lyon',
};

export default function checkError(zipcode) {
  return checkZipcodeError(zipcode).message;
}

export function getLocality(zipcode) {
  if (zipcode.startsWith(ParisZipcodePrefix)) {
    return 'Paris';
  }
  return otherZipcodes[zipcode];
}

export function getDeliveryFees(zipcode) {
  return getDeliveryFeesCore(zipcode);
}
