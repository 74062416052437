import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';

import OrderContainer from '../pagesComponents/common/OrderContainer';
import {
  Wrapper, Block, Header2, Title2, Input, ErrorText,
} from './styledComponents';
import { colors, Button } from './home/v2/StyledComponents';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const setTheme = (theme) => (
  {
    ...theme,
    borderRadius: 0,
    spacing: {
      ...theme.spacing,
      controlHeight: 55,
    },
    colors: {
      ...theme.colors,
      primary: colors.navy,
      primary25: colors.lightGrey,
      primary50: colors.lightGrey,
      neutral50: colors.darkGrey,
    },
  }
);

const Select = styled(ReactSelect)`
  font-size: 18px;
  margin-bottom: 20px;
`;

const AuthShop = ({
  shops, selectedShop, setSelectedShop, password, setPassword, submit, error,
}) => (
  <OrderContainer>
    <Wrapper>
      <Block>
        <Header2>
          <Title2>Connexion</Title2>
        </Header2>

        {!shops || shops.length === 0
          ? <span>Chargement</span>
          : (
            <div>
              <Select
                placeholder="Boutique"
                value={selectedShop}
                onChange={({ value }) => setSelectedShop(value)}
                options={shops.map((shop) => ({
                  label: shop.name,
                  value: shop, // eslint-disable-line no-underscore-dangle
                }))}
                theme={(theme) => setTheme(theme)}
              />

              <Input
                name="password"
                type="password"
                placeholder="Mot de passe"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <ButtonContainer>
                <Button onClick={submit}>
                  me connecter
                </Button>
              </ButtonContainer>

              {error
                && <ErrorText>La boutique et le mot de passe ne correspondent pas</ErrorText>}
            </div>
          )}
      </Block>
    </Wrapper>
  </OrderContainer>
);

AuthShop.propTypes = {
  shops: PropTypes.arrayOf(PropTypes.shape({})),
  selectedShop: PropTypes.string,
  setSelectedShop: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  submit: PropTypes.func,
  error: PropTypes.bool,
};

AuthShop.defaultProps = {
  shops: [],
  selectedShop: undefined,
  setSelectedShop() {},
  password: undefined,
  setPassword() {},
  submit() {},
  error: false,
};

export default AuthShop;
