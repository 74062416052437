import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { trackEvent } from '../../../services/analytics';
import {
  colors, mobileThresholdPixels, fontSizes, margins, menuBarHeightPixels, mobileMenuBarHeightPixels,
}
  from './StyledComponents';

const Ul = styled.ul`
  display: ${(props) => props.display ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: fit-content;
  padding: 20px ${margins.m};
  margin: 48px 0px 0px -14px;
  background-color: ${colors.white};
  color: ${colors.navy};
  z-index: 5;
  text-align: left;
  overflow:hidden;
  list-style-type: none;
  line-height: 32px;
  border-width: thin;
  border-style: solid;
  border-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    background-color: ${colors.navy};
    color: ${colors.white};
    top: 0px;
    height: calc(100vh - ${menuBarHeightPixels});
    margin: ${mobileMenuBarHeightPixels} 0px 0px 0px;
    padding: 20px 50px;
    border-width: 1px 0px 0px 0px;
  }
`;

const Li = styled.li`
  white-space: nowrap;
  &:hover {
    opacity: 0.7;
  }
`;

const ItemContainer = styled.div`
  cursor: pointer;
`;

const Span = styled.span`
  font-family: Gotham;
  font-size: ${fontSizes.s};
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    text-align: center;
  }
`;

function renderItem(item, onClick) {
  if (!item.url) {
    return (
      <ItemContainer
        onClick={() => {
          trackEvent('click', `${item.trackingId}_block-home-menu`);
          onClick(item.onClickAction);
        }}
      >
        <Span>{item.text}</Span>
      </ItemContainer>
    );
  }
  return (
    <Link
      to={item.url}
      style={{ textDecoration: 'none', color: 'inherit' }}
      onClick={() => trackEvent('click', `${item.trackingId}_block-home-menu`)}
    >
      <Span>{item.text}</Span>
    </Link>
  );
}

const DropdownList = ({ dropdownList, display, onClick }) => (
  <Ul display={display || undefined}>
    {dropdownList.map((item) => (
      <Li key={item.index}>
        {renderItem(item, onClick)}
      </Li>
    ))}
  </Ul>
);

DropdownList.propTypes = {
  dropdownList: PropTypes.arrayOf(PropTypes.object),
  display: PropTypes.bool,
  onClick: PropTypes.func,
};

DropdownList.defaultProps = {
  dropdownList: [],
  display: false,
  onClick() {},
};

export default DropdownList;
