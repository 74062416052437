import React from 'react';
import PropTypes from 'prop-types';

export default class OutInSideClick extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.handleClickOutside();
    } else {
      this.props.handleClickInside();
    }
  }

  render() {
    return (<div ref={this.setWrapperRef}>{this.props.children}</div>);
  }
}

OutInSideClick.propTypes = {
  children: PropTypes.node.isRequired,
  handleClickOutside: PropTypes.func,
  handleClickInside: PropTypes.func,
};

OutInSideClick.defaultProps = {
  handleClickOutside: () => {},
  handleClickInside: () => {},
};
