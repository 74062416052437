import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthShop from './AuthShop';
import callApi from '../services/api';
import featureSwitch from '../services/featureSwitch';
import withAuthContext from '../context/withAuthContext';

const isShopReadyForAtShop = (shop) => shop.customerForRetail;
const filterShopsForAtShop = (shops) => shops.filter(isShopReadyForAtShop);

class AuthShopContainer extends Component {
  constructor() {
    super();
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const brandId = featureSwitch('brandId');
    callApi(`brands/${brandId}/shops`).then(({ shops }) => {
      if (featureSwitch('step1_atShopRdv')) this.setState({ shops: filterShopsForAtShop(shops) });
      else this.setState({ shops });
    });
  }

  submit() {
    const { selectedShop: { _id: shopId }, password } = this.state;
    const { setAuth, setShop } = this.props.authContext;
    this.setState({ error: false });
    callApi('/shops/login', 'POST', { shopId, password })
      .then(({ shop }) => {
        setShop(shop);
        setAuth(true);
      })
      .catch(() => this.setState({ error: true }));
  }

  render() {
    const { shops, error } = this.state;
    return (
      <AuthShop
        shops={shops}
        setSelectedShop={(selectedShop) => this.setState({ selectedShop })}
        setPassword={(password) => this.setState({ password })}
        submit={this.submit}
        error={error}
      />
    );
  }
}

AuthShopContainer.propTypes = {
  authContext: PropTypes.shape({
    setAuth: PropTypes.func.isRequired,
    setShop: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAuthContext(AuthShopContainer);
