// cSpell:ignore mariee
import React from 'react';
import PropTypes from 'prop-types';
import { Background, Container } from '../../components/styledComponents';
import tilliBackgroundImage from '../../assets/headerV2.jpg';
import maduraBackgroundImage from '../../assets/brand/Madura.jpg';
import sezaneBackgroundImage from '../../assets/brand/Sezane2.jpg';
import galeriesLafayetteBackgroundImage from '../../assets/brand/GaleriesLafayetteBackground.jpg';
import sessunBackgroundImage from '../../assets/brand/Sessun.jpg';
import laMarieeCapricieuseBackgroundImage from '../../assets/brand/LaMarieeCapricieuse.jpg';
import sequoiaPressingBackgroundImage from '../../assets/brand/SequoiaPressingBackground.jpg';
import aigleBackgroundImage from '../../assets/brand/aigle.jpg';
import zaraBackgroundImage from '../../assets/brand/zara.jpg';
import cinqASecBackgroundImage from '../../assets/brand/5asec.jpg';
import mokkiBackgroundImage from '../../assets/brand/MokkiBackground.jpg';
import mariaDeLaOrdenBackgroundImage from '../../assets/brand/MariaDeLaOrdenBackground.jpg';
import marianneBackgroundImage from '../../assets/brand/MarianneBackground.jpg';
import soeurBackgroundImage from '../../assets/brand/soeurBackground.jpg';

function getBackgroundImage() {
  switch (process.env.GATSBY_BRAND) {
    case 'MADURA':
      return maduraBackgroundImage;
    case 'SEZANE':
      return sezaneBackgroundImage;
    case 'GALERIES_LAFAYETTE':
    case 'GALERIES_LAFAYETTE_LYON':
      return galeriesLafayetteBackgroundImage;
    case 'SESSUN':
      return sessunBackgroundImage;
    case 'LA_MARIEE_CAPRICIEUSE':
      return laMarieeCapricieuseBackgroundImage;
    case 'SEQUOIA_PRESSING':
      return sequoiaPressingBackgroundImage;
    case 'AIGLE':
      return aigleBackgroundImage;
    case 'ZARA':
      return zaraBackgroundImage;
    case '5ASEC':
      return cinqASecBackgroundImage;
    case 'MOKKI':
      return mokkiBackgroundImage;
    case 'MARIA_DE_LA_ORDEN':
      return mariaDeLaOrdenBackgroundImage;
    case 'MARIANNE':
      return marianneBackgroundImage;
    case 'SOEUR':
      return soeurBackgroundImage;
    default:
      return tilliBackgroundImage;
  }
}
const backgroundImage = getBackgroundImage();

const OrderContainer = ({ children }) => (
  <div>
    <Background backgroundImage={backgroundImage} />
    <Container>
      {children}
    </Container>
  </div>
);

OrderContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderContainer;
